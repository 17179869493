import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../components/navbar.js";
import Footer from "../components/footer.js";
import "../css/index.css";
import favicon from '../assets/favicon.ico';

const RechtlichesPage = () => {
  return (
    <div>
      <Helmet>
        <title>Pneuhaus Rogenmoser</title>
        <meta charset="utf-8" />
        <meta name="description" content="Willkommen bei Pneuhaus Rogenmoser in Auw. Ihr Reifenhändler in der Region Freiamt." />
        <meta name="keywords" content="Pneu,Pneuservice,Pneuverkauf,Pneuhandel,Reifenservice,Reifenhandel,Auw,Muri,Merenschwand,Sins,Freiamt,Aargau,Reifen," />
        <meta name="robots" content="noindex" />
        <meta name="author" content="Melanie Rogenmoser"/>
        <meta name="copyright" content="Melanie Rogenmoser"/>
        <meta name="date" content="2022-08-09"/>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <Navbar />
      <div className="containerimpressum">
      <div className="text">
          <h2>Rechtliches</h2>
          <h5>Alle Medien und Inhalte die Sie auf der Webseite www.pneuhaus-rogenmoser.ch sehen, ist Eigentum von der Firma Pneuhaus Rogenmoser.<br />
          Der Inhalt dieser Internetseiten ist urheberrechtlich geschützt. Es darf eine Kopie der Informationen der Internetseiten auf einem einzigen Computer für den nicht-kommerziellen und persönlichen internen Gebrauch gespeichert werden. Grafiken, Texte, Logos, Bilder usw. dürfen nur nach schriftlicher Genehmigung durch das Pneuhaus Rogenmoser heruntergeladen, vervielfältigt, kopiert, geändert, veröffentlicht, versendet, übertragen oder in sonstiger Form genutzt werden.</h5>

      </div>
      </div>
      <Footer/>
    </div>
  );
};

export default RechtlichesPage;